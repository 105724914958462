@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  background-color: #0d0d0d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom Tailwind CSS for neon button */
.bg-neon-blue {
  background-color: #0d0d0d;
} 

 .bg-neon-blue-light:hover {
  background-color: #0d0d0d;
}

/* Add this to your CSS file */
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


